import { createStore,applyMiddleware,combineReducers,compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers'
// import logger from 'redux-logger'
import mid from '../@mid'

export default function configureStore(initialState = {},{ reducers = {},middlewares = [] } = {}) {
  return createStore(
    combineReducers({ ...rootReducer,...reducers }),
    initialState,
    compose(applyMiddleware(thunk,mid))
  );
}


// export default function create(
//   initialState = {},
//   { reducers = {}, middlewares = [] } = {}
// ) {
//   var devTool = f => f
//   if (typeof window !== 'undefined') {
//     devTool = window.devToolsExtension ? window.devToolsExtension() : f => f
//   }
//   const store = createStore(
//     combineReducers({
//       ...rootReducers,
//       ...reducers
//     }),
//     initialState,
//     compose(
//       applyMiddleware(thunkMiddleware, ...middlewares, awaitMiddleware),
//       devTool
//     )
//   )
//   if (process.env.NODE_ENV !== 'production') {
//     if (module.hot) {
//       module.hot.accept('./reducers', () => {
//         store.replaceReducer(...rootReducers, ...reducers)
//       })
//     }
//   }
//   return store
// }


// export default createStore(combineReducers(rootReducer),applyMiddleware(thunk))