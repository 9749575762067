import React from 'react';
import './App.css';
import './themes/animate.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import Routers from './@nav'
import createStore from './@redux'
import 'antd/dist/antd.css'
const browserHistory = require("history").createBrowserHistory;

const history = browserHistory()

const getStoreDefault = () => {
  if (typeof window !== 'undefined')
    return window.__REDUX_STORE__ ? window.__REDUX_STORE__ : {}
  return {}
}

function App() {
  return (
    <Provider store={createStore(getStoreDefault(),{ routerHistory: history })}>
      <Router>
        <Routers />
      </Router>
    </Provider>
  );
}

export default App;
