if (process.env.NODE_ENV !== 'production') {
  require('dotenv').config()
}

export const API = process.env.WEB_API || 'https://moitruong.stnmt-hagiang.com.vn/eos/api_led/call/json'
export const API_QTDK = process.env.API_QTDK || 'https://qtdk-api.stnmt-hagiang.com.vn'
export const AT = process.env.AT || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDkyMDg4MjksImV4cCI6MTY0MDc0NDgyOSwic3ViIjoiNWY5ZmM5YjgyNzU5ODJiZjkzNWI2YjkxIiwicmV2b2tlZCI6IlIxOTk1In0.V5Q72JfXpIrv-Uq57I75mzfiVeYJo21YtoVUomRh3A4'
export const PORT = process.env.PORT || 5055

export function toAuthUrl(prefix = '') {
  if (prefix)
    return `${API}/${prefix}`
  return API
}

export function toUrl(prefix = '') {
  if (prefix)
    return `${API}/${prefix}`
  return API
}

export function toUrlQTDK(prefix = '') {
  if (prefix)
    return `${API_QTDK}/${prefix}`
  return API_QTDK
}
