import React, { Component } from 'react'
const RESET_INTERVAL_S = 300

const formatTime = (time) =>
  `${String(Math.floor(time / 60)).padStart(2, "0")}:${String(
    time % 60
  ).padStart(2, "0")}`;

export default class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0
    };
  }

  timerId = null;

  componentDidMount() {
    this.timerId = setInterval(() => {
      if (this.state.time % RESET_INTERVAL_S === 0) {
        this.props.fetchData()
      }
      this.setState((prevState) => ({ time: prevState.time + 1 }));
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  render() {
    const timeRemain = RESET_INTERVAL_S - (this.state.time % RESET_INTERVAL_S);

    return (
      <h3 className='normal-text' hidden>Chuyển trạm kế tiếp trong {formatTime(timeRemain)}</h3>
    )
  }
}
