import api from '../../api'
import * as _ from 'lodash'
import { hex2long } from '../../utils/ext-func'
import moment from 'moment'

export const FETCH_STATION = 'STATION/fetch-stations'
export const SELECTED_STATION = 'STATION/selected-station'
export const SELECTED_STATION_TYPE = 'STATION-TYPE/selected-station-type'
export const SELECTED_INDICATOR = 'INDICATOR/selected-indicator'
export const FETCH_DATA = 'DATA/fetch-data'
export const FETCH_DATA_AQI = 'DATA/fetch-data-aqi'

export const FETCH_AQI = 'AQI/fetch-aqi-day'

export function fetchStations(station_type, station = null) {
  return async dispatch => {
    dispatch(onChangeStationType(station_type))
    const { data } = await api.get_stations(_.toNumber(station_type))
    if (station) {
      const station_id = hex2long(station._id)
      const indicators = _.map(station.indicators, ({ name }) => name)
      dispatch(fetchData(station_id, indicators))
    } else {
      station = _.first(data)
      dispatch(onChangeStation(station, station_type))
    }

    dispatch({ type: FETCH_STATION, payload: data })
  }
}

export const onChangeStationType = (data) => ({ type: SELECTED_STATION_TYPE, payload: data })


export function onChangeStation(station, station_type = '1') {
  return async dispatch => {
    if (station) {
      const station_id = hex2long(station._id)
      const indicators = _.map(station.indicators, ({ name }) => name)
      dispatch({ type: SELECTED_STATION, payload: { station, indicators: station.indicators, indicator: _.first(station.indicators) } })
      if (station_type === '1')
        dispatch(fetchData(station_id, indicators))
      else dispatch(fetchDataAqi(station_id, station.last_time))
    } else {
      dispatch({ type: SELECTED_STATION, payload: { station: {}, indicators: [], indicator: {} } })
      dispatch({ type: FETCH_DATA, payload: [] })
    }
  }
}


export function fetchDataAqi(station_id, time) {
  return async dispatch => {
    dispatch(fetchAqiDay(station_id))
    let { data } = await api.get_data_of_day({ station_id, time })
    
    if (!data) {
      data = []
    }
    data = _.map(data, item => ({
      ...item, time: moment(item.get_time).unix() * 1000,//format('HH:mm'),
      thoigian: moment(item.get_time).subtract(7, 'hours')
    }))
    dispatch({ type: FETCH_DATA_AQI, payload: data })
  }
}

export function fetchData(station_id, indicators) {
  return async dispatch => {
    // dispatch(fetchAqiDay(station_id))
    let { data } = await api.get_data({ station_id, indicators })
    if (!data) {
      data = []
    }
    data = _.map(data, item => ({
      // ...item,time: moment(item.time).subtract(7, 'hour').unix()*1000//format('HH:mm')
      ...item, time: moment(item.time).unix() * 1000,//format('HH:mm')
      thoigian: moment(item.get_time).subtract(7, 'hours')
    }))
    dispatch({ type: FETCH_DATA, payload: data })
  }
}

export const onChangeIndicator = indicator => ({ type: SELECTED_INDICATOR, payload: indicator })

export function fetchAqiDay(station_id) {
  return async dispatch => {
    const { data } = await api.get_api_day(station_id)
    dispatch({ type: FETCH_AQI, payload: data })
  }
}
