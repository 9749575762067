import update from 'react-addons-update'
import * as _ from 'lodash'
import {
  FETCH_STATION,
  FETCH_AQI,
  FETCH_DATA,
  SELECTED_STATION_TYPE,
  SELECTED_STATION,
  SELECTED_INDICATOR,
  FETCH_DATA_AQI
} from '../actions/station'

const initState = {
  station_type: '1',
  stations: [],
  station: {},
  indicators: [],
  indicator: {},
  data: [],
  aqiDay: {},
  dataAqi: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case FETCH_STATION:
      return update(state, {
        stations: { $set: action.payload }
      })
    case SELECTED_STATION_TYPE:
      return update(state, {
        station_type: { $set: action.payload }
      })
    case FETCH_DATA_AQI:
      return update(state, { dataAqi: { $set: action.payload } })
      case FETCH_DATA:
      return update(state, { data: { $set: action.payload } })
    case FETCH_AQI:
      return update(state, { aqiDay: { $set: action.payload } })
    case SELECTED_STATION:
      return selectedStation(state, action)
    case SELECTED_INDICATOR:
      return update(state, { indicator: { $set: action.payload } })
    default:
      return state
  }
}

function selectedStation(state, { payload }) {

  const params = {
    station: { $set: payload.station },
    indicators: { $set: payload.indicators },
    // indicator: { $set: payload.indicator }
  }

  if (!_.isEqual(_.get(state, 'station.station_code'), _.get(payload, 'station.station_code')))
    params.indicator = { $set: payload.indicator }

  return update(state, params)
}
