import axios from 'axios'
import { API_QTDK, AT } from './../config-app'



function getHeaders() {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AT}`
    }
    return { headers }
}

export const getStationListWQI = async () => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${API_QTDK}/share-data/get-station-list-wqi`, "", getHeaders())
            .then(result => resolve(result.data))
            .catch(err => reject(err))
    })
}

export const getDataChart = async () => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${API_QTDK}/wqi/data-map`, "", getHeaders())
            .then(result => resolve(result.data))
            .catch(err => reject(err))
    })
}
