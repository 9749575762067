import React, { Component } from 'react'
import moment from 'moment'
export default class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date() };
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }

  render() {
    return (
      <h3 className='text-red'>{moment(this.state.date).format('HH:mm:ss DD/MM/YYYY')}</h3>
    )
  }
}
