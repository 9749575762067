export const menus = [
  { key: 'dashboard', name: 'Dashboard', icon: 'desktop' },
  { key: 'plans', name: 'Plan', icon: 'profile' },
  {
    key: 'data', name: 'Data SearchSearch', isParent: true, icon: 'file-search',
    routes: [
      { key: 'input-data', name: 'Data' },
      { key: 'search-data', name: 'Data Search' },
    ]
  },
  { key: 'map', name: 'Map', icon: 'environment' },
  {
    key: 'category', name: 'Category', isParent: true, icon: 'pie-chart',
    routes: [
      { key: 'stationtype', name: 'Station Type' },
      { key: 'station', name: 'Station' },
      { key: 'measuring', name: 'Measuring' },
      { key: 'qcvn', name: 'QCVN' },
      { key: 'province', name: 'Province' },
      { key: 'times', name: 'Times' },
      { key: 'enterprise', name: 'Enterprise' },
      { key: 'department', name: 'Department' },
      { key: 'analysisMethod', name: 'Analysis Method' },
    ]
  },
  {
    key: 'administrators', name: 'Managerment', isParent: true, icon: 'setting',
    routes: [
      { key: 'users', name: 'Users' },
      { key: 'roles', name: 'Roles' },
    ]
  },
  {
    key: 'report', name: 'Report', isParent: true, icon: 'unordered-list',
    routes: [
      { key: 'report-tt43a1', name: 'A1' },
      { key: 'report-tt43a2', name: 'A2' },
    ]
  },

]

export default {
  home: '/',
  wqi_qtdk:'/wqi-qtdk',
  led_aqi:'/led-aqi',
  aqi_led:'/led-multiple'
}
