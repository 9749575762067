import React, { Component } from 'react'
import './index.css'
import './led-multiple.css'
import styled from 'styled-components'
import api from "../../api";
import * as _ from 'lodash'
import Clock from './clock'
import Timer from './timer'
import AqiView from './aqi-view'
import WqiView from './wqi-view'
import { Carousel } from 'antd';
import { getStationListWQI } from '../../api/api-qtdk'


const ImageLogo = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 12px
`

const station_type = 4

export default class LedAQI extends Component {

    constructor(props) {
        super(props)
        this.state = {
            aqiStationsList: [],
            wqiStationsList: []
        }
    }

    fetchAqi = async () => {
        const { success, data } = await api.get_stations(station_type)
        if (success) {
            if (!_.isEqual(data, this.state.stationsList)) {
                this.setState({ aqiStationsList: _.chunk(data, 4) })
            }
        }
    }
    fetchWqi = async () => {
        const { success, data } = await getStationListWQI()
        if (success) {
            if (!_.isEqual(data, this.state.wqiStationsList)) {
                this.setState({ wqiStationsList: _.chunk(data, 4) })
            }
        }
    }


    fetchData = async () => {
        await this.fetchAqi()
        await this.fetchWqi()
    }

    render() {
        return (
            <div className='led-container'>
                <div className='led-header'>
                    <div>
                        <ImageLogo src='./logo.png' />
                        <h3 className='text-blue'>SỞ TÀI NGUYÊN VÀ MÔI TRƯỜNG TỈNH HÀ GIANG {this.state.number}</h3>
                    </div>
                    <div>
                        <Clock />
                    </div>
                </div>
                <div className='slide-multiple'>
                    <Carousel autoplay autoplaySpeed={15000} className='carousel' dots={false}>
                        {this.state.aqiStationsList.map(item => (
                            <AqiView stationsList={item} />
                        ))}
                        {this.state.wqiStationsList.map(item => (
                            <WqiView wqiStationsList={item} />
                        ))}
                    </Carousel>
                </div>
                <Timer fetchData={this.fetchData} />
            </div >
        )
    }
}
