import React from 'react'
import {Route,Switch } from 'react-router-dom'
import slug from '../constants/slug'
// import HomeContainer from '../containers/app'
// import WQIQTDKContainer from '../containers/wqi-qtdk'
// import LedAQIContainer from '../containers/led-aqi'
import LedMultipleContainer from '../containers/led-multiple'



export default props => (
  <Switch>
    <Route exact path={slug.home} component={LedMultipleContainer} {...props}/>
    <Route path={slug.wqi_qtdk} component={LedMultipleContainer} {...props}/>
    <Route path={slug.led_aqi} component={LedMultipleContainer} {...props}/>
    <Route path={slug.aqi_led} component={LedMultipleContainer} {...props}/>
  </Switch>
)