import {
  LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_ROLE_SUCCESS
} from '../actions/auth'

const initState = {
  user: null,
  isAuthenticated: false,
  role: {}
}

export default (state = initState,action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state,user: action.payload,isAuthenticated: true }
    case AUTH_LOGOUT_SUCCESS:
      return { ...state,isAuthenticated: false }
    case AUTH_ROLE_SUCCESS:
      return { ...state,role: action.payload }
    default:
      return state
  }
}