import React from 'react'
import './index.css'
import './led-multiple.css'
import styled from 'styled-components'

const AQITitleText = styled.div`font-size: 24px; font-weight: 900; color: #fff; margin: 4px; flex: 1; text-align:center`
const AQIText = styled.div`font-size: 20px; font-weight: 700; color: #fff; text-align:center`


const TableHeader = () => (
  <>
    <div className='led-multiple-content-table-header led-border-top-big'>
      <AQITitleText>Chỉ số chất lượng không khí VN_AQI</AQITitleText>
    </div>
    <div className='led-multiple-content-table-header led-border-top-big'>
      <div className='led-multiple-content-child led-border-right-big'>
        <AQIText>Tên trạm</AQIText>
      </div>
      <div className='led-multiple-content-aqi-child led-border-right-big'>
        <AQIText>VN_AQI Giờ</AQIText>
      </div>

      <div className='led-multiple-content-aqi-child led-border-right-big'>
        <AQIText>VN_AQI Ngày</AQIText>
      </div>
      <div className='led-multiple-content-last-child'>
        <AQIText>Khuyến nghị về sức khỏe (theo AQI Giờ)</AQIText>
      </div>
    </div>
  </>
)

export default TableHeader

